import { request } from "./request";

/**
 * 统一api管理
 * @param data
 * @returns {AxiosPromise}
 */

export function getIsGray(data = {}) {
    return request({
        url: "/api/isGray",
        method: "post",
        data: data
    });
}

// 个人注册
export function personalBasics(data) {
    return request({
        url: "/api/personalBasics",
        method: "post",
        data: data
    });
}
// 团体注册
export function groupBasics(data) {
    return request({
        url: "/api/groupBasics",
        method: "post",
        data: data
    });
}
// 团体注册 单位类型
export function unitType(data) {
    return request({
        url: "/api/unitType",
        method: "get",
    });
}
// 获取验证码
export function getPhoneCode(data) {
    return request({
        url: "/api/getPhoneCode",
        method: "post",
        data
    });
}

// 登录
export function checkLogin(data) {
    return request({
        url: "/api/checkLogin",
        method: "post",
        data
    });
}
// 获取 (个人) 身份验证信息
export function getPeopleAuthenInfo(data) {
    return request({
        url: "/api/getPeopleAuthenInfo",
        method: "post",
        data
    });
}
// code
export function getCode(data) {
    return request({
        url: "/api/getCode",
        method: "post",
        data
    });
}
// 搜索
export function search(data) {
    return request({
        url: "/api/search",
        method: "post",
        data
    });
}

export function articleSearch(data) {
    return request({
        url: "/api/articleSearch",
        method: "post",
        data
    });
}
// 用户信息
export function userInfo(data) {
    return request({
        url: "/api/userInfo",
        method: "post",
        data
    });
}
// 退出登入
export function checkLogout(data) {
    return request({
        url: "/api/checkLogout",
        method: "post",
        data
    });
}

// 修改手机号
export function updatePhone(data) {
    return request({
        url: "/api/updatePhone",
        method: "post",
        data
    });
}

// 修改密码
export function updatePassword(data) {
    return request({
        url: "/api/updatePassword",
        method: "post",
        data
    });
}



export function getTopBanner(data) {
    return request({
        url: "/api/getBanner",
        method: "post",
        data: data
    });
}
// 页面的内容数据
export function getIndexColumn(stationId) {
    return request({
        url: "/api/findIndexColumn?stationId=" + stationId,
        method: "get",
    });
}
// 轮播图
export function getIndexImgList(data = {}) {
    return request({
        url: "/api/findImgList",
        method: "post",
        data: data
    });
}
// 友情链接
export function getFriendLink(data = {}) {
    return request({
        url: "/api/findBlogrollList",
        method: "post",
        data: data
    });
}
export function specialList(data) {
    return request({
        url: "/api/specialList",
        method: "post",
        data: data
    });
}
export function getNav(data = {}) {
    return request({
        url: "/api/findNewBar",
        method: "post",
        data: data
    });
}
export function findFooter(data = {}) {
    return request({
        url: "/api/findFooter",
        method: "post",
        data: data
    });
}
// 栏目下内容
export function getArticleListById(data = {}) {
    return request({
        url: "/api/findArticleListById",
        method: "post",
        data: data
    });
}
// 文章详情
export function getArticleData(data = {}) {
    return request({
        url: "/api/findArticle",
        method: "post",
        data: data
    });
}
// 单位简介
export function getArticleById(data = {}) {
    return request({
        url: "/api/findArticleById",
        method: "post",
        data: data
    });
}
// 赛事性质
export function natureOfTheCompetition(data) {
    return request({
        url: "/api/natureOfTheCompetition",
        method: "get",
        data: data
    });
}
// 赛事种类
export function eventType(data) {
    return request({
        url: "/api/eventType",
        method: "get",
        data: data
    });
}
// 查询运动项目
export function sportEventList(data) {
    return request({
        url: "/api/sportEventList",
        method: "post",
        data: data
    });
}
// 查询运动项目
export function sportEvent(data) {
    return request({
        url: "/api/sportEvent",
        method: "post",
        data: data
    });
}
// 赛事信息
export function matchList(data) {
    return request({
        url: "/api/matchList",
        method: "post",
        data: data
    });
}
// 最高纪录分组
export function highestRecordGroup(data) {
    return request({
        url: "/api/highestRecordGroup",
        method: "post",
        data: data
    });
}
// 三级栏目
export function findAllColumn(data) {
    return request({
        url: "/api/findAllColumn",
        method: "post",
        data
    });
}

// 赛事栏目内容
export function matchArticle(data) {
    return request({
        url: "/api/matchArticle",
        method: "post",
        data
    });
}

// 赛事报名
export function eventData(data) {
    return request({
        url: "/api/eventData",
        method: "post",
        data
    });
}

// 赛事报名 列表
export function getMatchBasicInfo(data) {
    return request({
        url: "/api/getMatchBasicInfo",
        method: "post",
        data
    });
}


// 赛事报名 列表
export function getMatchScoreList(data) {
    return request({
        url: "/api/getMatchScoreList",
        method: "post",
        data
    });
}


// 裁判个人列表
export function getRefereeListInfo(data) {
    return request({
        url: "/api/getRefereeListInfo",
        method: "post",
        data
    });
}

// 裁判详情
export function getRefereeDetails(data) {
    return request({
        url: "/api/getRefereeDetails",
        method: "post",
        data
    });
}
// 裁判员证书全览详情
export function getUserAllRefereeDeta(data) {
    return request({
        url: "/api/getUserAllRefereeDeta",
        method: "post",
        data
    });
}
// 裁判等级
export function judgmentLevel(data) {
    return request({
        url: "/api/judgmentLevel",
        method: "get",
        data
    });
}

// 地区
export function areaList(data) {
    return request({
        url: "/api/areaList",
        method: "get",
        data
    });
}

// 专题列表
export function findList1(data) {
    return request({
        url: "/api/findList",
        method: "get",
        data
    });
}

// 获奖情况统计
export function medalRankingList(data, type) {
    return request({
        url: "/api/medalRankingList?id=" + data + '&type=' + type,
        method: "get",
        data
    });
}

// 专题里导航栏下内容
export function findArticleByColumn(data) {
    return request({
        url: "/api/findArticleByColumn",
        method: "post",
        data
    });
}

// 项目下运动项目 赛事查询
export function getRefereeSports(data) {
    return request({
        url: "/api/getRefereeSports",
        method: "post",
        data
    });
}
// 首页精彩瞬间
export function homeImage(id) {
    return request({
        url: "/api/homeImage?id=" + id,
        method: "get",
    });
}

// 专题下赛事列表
export function matchInfoList(data) {
    return request({
        url: "/api/matchInfoList",
        method: "post",
        data
    });
}

// 首页logo
export function findStationById(id) {
    return request({
        url: "/api/findStationById?id=" + id,
        method: "get"
    });
}

// 赛事成绩分组数据
export function gradeGrouping(data) {
    return request({
        url: "/api/gradeGrouping",
        method: "post",
        data
    });
}

// 赛事成绩分组数据 查看个人
export function eventResultsDetails(data) {
    return request({
        url: "/api/eventResultsDetails",
        method: "post",
        data
    });
}

// 相关推荐
export function recommend(id) {
    return request({
        url: "/api/recommend?id=" + id,
        method: "get",
    });
}

// 知识竞赛下栏目
export function findLabelList(data) {
    return request({
        url: "/api/findLabelList",
        method: "post",
        data
    });
}

// 裁判员 选派记录
export function getDispatchMatchByRefereeId(data) {
    return request({
        url: "/api/getDispatchMatchByRefereeId",
        method: "post",
        data
    });
}

// 赛事 日历时间
export function matchInfoDate(data) {
    return request({
        url: "/api/matchInfoDate",
        method: "post",
        data
    });
}

export function matchInfoDateOther(data) {
    return request({
        url: "/api/matchInfoDateOther",
        method: "post",
        data
    });
}


// 赛事简介 赛事详情
export function eventIntroduction(id) {
    return request({
        url: "/api/eventIntroduction?matchId=" + id,
        method: "get",
    });
}
// 赛事报名详情
export function getBasicEnrollInfoByMatchId(id) {
    return request({
        url: "/api/getBasicEnrollInfoByMatchId?id=" + id,
        method: "get",
    });
}

// 赛事规程组别项目
export function getMatchInfoByMatchId(data) {
    return request({
        url: "/api/getMatchInfoByMatchId",
        method: "post",
        data
    });
}

// 专题里赛事安排
export function matchInfoListAll(data) {
    return request({
        url: "/api/matchInfoListAll",
        method: "post",
        data
    });
}

// 注册裁判员
export function registerRefereeCertificate(data) {
    return request({
        url: "/api/registerRefereeCertificate",
        method: "post",
        data
    });
}

// 编辑裁判员
export function updateRefereeCertificate(data) {
    return request({
        url: "/api/updateRefereeCertificate",
        method: "post",
        data
    });
}

// 编辑裁判员2
export function refereeCertificateUpdateSubmit(data) {
    return request({
        url: "/api/refereeCertificateUpdateSubmit",
        method: "post",
        data
    });
}

// 身份验证
export function submitPeopleAuthen(data) {
    return request({
        url: "/api/submitPeopleAuthen",
        method: "post",
        data
    });
}

// 名族
export function sysUserRegion(data) {
    return request({
        url: "/api/sysUserRegion",
        method: "get",
    });
}

// 查看照片
export function getFile(data) {
    return request({
        url: "/api/getFile",
        method: "post",
        data
    });
}

// 栏目
export function columnList(data) {
    return request({
        url: "/api/columnList",
        method: "get",
    });
}
// 分享
export function wxShare(data) {
    return request({
        url: "/api/wxShare",
        method: "post",
        data
    });
}

// 获取服务器时间
export function getTimeStamp(data) {
    return request({
        url: "/api/getTimeStamp",
        method: "get",
    });
}

// 获取团体用户实名
export function getCommunityAuthenInfo(data) {
    return request({
        url: "/api/getCommunityAuthenInfo",
        method: "post",
        data
    });
}

// 个人编辑与重新提交身份验证
export function resubmitPeopleAuthen(data) {
    return request({
        url: "/api/resubmitPeopleAuthen",
        method: "post",
        data
    });
}

// 加密
export function encoderByAes(data) {
    return request({
        url: "/api/encoderByAes",
        method: "post",
        data
    });
}

// 首页精彩赛事推荐轮播
export function getWonderfulMatch(data) {
    return request({
        url: "/api/getWonderfulMatch",
        method: "post",
        data
    });
}

// 个人裁判
export function getRefereeInfoList(data) {
    return request({
        url: "/api/getRefereeInfoList",
        method: "post",
        data
    });
}
//个人成绩查询
export function getMatchSorceDetailsByName(data) {
    return request({
        url: "/api/getMatchSorceDetailsByNameNew",
        method: "post",
        data
    });
}

//个人成绩查询
export function bjSport(id, head) {
    return request({
        url: "/api/bjSport?id=" + id + '&head=' + head,
        method: "get",
    });
}
//找回密码
export function userResettingPwd(data) {
    return request({
        url: "/api/userResettingPwd",
        method: "post",
        data
    });
}
//赛事组别、项目、赛次三级联动接口
export function getMatchScoreThreeLinkQuery(data) {
    return request({
        url: "/api/getMatchScoreThreeLinkQuery",
        method: "post",
        data
    });
}
//运动员管理
export function sportsmanList(data) {
    return request({
        url: "/api/sportsmanList",
        method: "post",
        data
    });
}
//项目
export function getMatchHighestScoreThreeLinkQuery(data) {
    return request({
        url: "/api/getMatchHighestScoreThreeLinkQuery",
        method: "post",
        data
    });
}
export function idCardType(data) {
    return request({
        url: "/api/idCardType",
        method: "post",
        data
    });
}

export function yearList(data) {
    return request({
        url: "/api/yearList",
        method: "post",
        data
    });
}


export function ticketBanner(stationId) {
    return request({
        url: "/api/ticketBanner?stationId=" + stationId,
        method: "get",
    });
}


export function getUserAge(data) {
    return request({
        url: "/api/getUserAge",
        method: "post",
        data
    });
}
// 新版 成绩查询
export function getSportInfoByName(data) {
    return request({
        url: "/api/getSportInfoByName",
        method: "post",
        data
    });
}
// 新版 通过身份证查询裁判员信息
export function getRefereeDetailsByCard(data) {
    return request({
        url: "/api/getRefereeDetailsByCard",
        method: "post",
        data
    });
}
// 新版 获取成绩列表
export function getSportsmanScore(data) {
    return request({
        url: "/api/getSportsmanScore",
        method: "post",
        data
    });
}
// 新版 个人中心
export function getUserCardInfo(data) {
    return request({
        url: "/api/getUserCardInfo",
        method: "post",
        data
    });
}

export function getRefereeSportsDictItem(data) {
    return request({
        url: "/api/getRefereeSportsDictItem",
        method: "post",
        data
    });
}
// 个人头像
export function updateAvatar(data) {
    return request({
        url: "/api/updateAvatar",
        method: "post",
        data
    });
}

// 新版 裁判员证书全览列表
export function getRefereeBase(data) {
    return request({
        url: "/api/getNewRefereeBase",
        method: "post",
        data
    });
}

// // 老版 裁判员证书全览列表
// export function getRefereeBase(data) {
//     return request({
//         url:"/api/getRefereeBase",
//         method: "post",
// 		data
//     });
// }

// 新版 裁判员证书列表
export function selectAllRefereeUser(data) {
    return request({
        url: "/api/getNewAllRefereeUser",
        method: "post",
        data
    });
}

// // 老版 裁判员证书列表
// export function selectAllRefereeUser(data) {
//     return request({
//         url:"/api/selectAllRefereeUser",
//         method: "post",
// 		data
//     });
// }


// 人脸二维码
export function personalVerification(data) {
    return request({
        url: "/api/personalVerification",
        method: "post",
        data
    });
}

// 新版 身份验证
export function submitFaceAuth(data) {
    return request({
        url: "/api/submitFaceAuth",
        method: "post",
        data
    });
}

// 新版 免冠照上传
export function reSubmitFaceAuth(data) {
    return request({
        url: "/api/reSubmitFaceAuth",
        method: "post",
        data
    });
}

// 新版 获取实名状态
export function getCaStatus(data) {
    return request({
        url: "/api/getCaStatus",
        method: "post",
        data
    });
}

// 报名列表
export function projectRegList(data) {
    return request({
        url: "/api/projectRegList",
        method: "post",
        data
    });
}

// 报名常用人列表
export function getUserContact(data) {
    return request({
        url: "/api/getUserContact",
        method: "post",
        data
    });
}

// 新增报名常用人列表
export function addUserContact(data) {
    return request({
        url: "/api/addUserContact",
        method: "post",
        data
    });
}

// 添加个人报名
export function projectEnroll(data) {
    return request({
        url: "/api/projectEnroll",
        method: "post",
        data
    });
}

// 个人报名列表
export function userEnrollList(data) {
    return request({
        url: "/api/userEnrollList",
        method: "post",
        data
    });
}

// 个人报名详情
export function userEnrollInfo(data) {
    return request({
        url: "/api/userEnrollInfo",
        method: "post",
        data
    });
}

// 个人报名保存提交
export function submitUserEnroll(data) {
    return request({
        url: "/api/submitUserEnroll",
        method: "post",
        data
    });
}

// 报名常用人编辑
export function saveUserContact(data) {
    return request({
        url: "/api/saveUserContact",
        method: "post",
        data
    });
}

// 报名常用人删除
export function removeUserContact(data) {
    return request({
        url: "/api/removeUserContact",
        method: "post",
        data
    });
}