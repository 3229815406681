// 触发微信分享
function getwxsdk(val,img) {
	// 配置微信分享
	let data = {
		title: val,
		desc: '',
		imgUrl: img || 'https://www.bjcac.org.cn/upload/2023/08/04/d134036c-f38d-4d2f-bc3d-411c4fc4b971.jpg'
	}
	this.$wxsdk(data)
}

export {
	getwxsdk
}