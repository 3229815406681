import axios from 'axios'
import {
	MessageBox,
	Message
} from 'element-ui'
import store from "@/store/index"
import {
	verification
} from '../utils/auth.js'

//1. 创建新的axios实例，
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // 公共接口
	timeout: 8000, // 超时时间
});

// 2.请求拦截器
service.interceptors.request.use(
	config => {
		//发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
		config.headers = {
			// 'Content-Type': 'application/json utf-8', // 配置请求头
			'stationId': store.state.stationId
		};
		const token = store.state.token; // token登录时存储
		if(config.url != '/api/getTimeStamp'){
			if (token) {
				config.headers.token = token; // token携带在请求头中
			}
		}
		// if (token) {
		// 	// 将token添加到请求体中
		// 	if (config.data) {
		// 		config.data['token'] = token
		// 	}else{
		// 		config.data = {}
		// 		config.data.token = token
		// 	}
		// }
		return config;
	},
	error => {
		Promise.reject(error);
	}
);

// 3.响应拦截器
service.interceptors.response.use(
	response => {
		if(!verification(response.data)){
			if(!verification(response.data.data)){
				if(!verification(response.data.data.result)){
					if(response.data.data.result.state == 1){
						MessageBox.alert(response.data.data.message, '系统提示', {
						  confirmButtonText: '去修改',
						  type: 'warning'
						}).then(async () => {
						  //注销去登录页
						  store.commit("EXIT_TOKEN")
						  if(window.location.href.includes('/h5')){
							location.href = '/h5/changePassword'  
						  }else{
							location.href = '/changePassword'  
						  }
						})
						return
					}
				}
			}
		}
		if(response.data.code == -1){
			MessageBox.alert('登录状态已过期，请重新登录', '系统提示', {
			  confirmButtonText: '重新登录',
			  type: 'warning'
			}).then(async () => {
			  //注销去登录页
			  store.commit("EXIT_TOKEN")
			  if(window.location.href.includes('/h5')){
				location.href = '/h5/login'  
			  }else{
				location.href = '/login'  
			  }
			})
			return
		} else if (response.data.code == 500) {
			Message({
				message: response.data.msg || response.data.message,
				type: 'error',
			});
			return Promise.reject(new Error(response.data.msg || response.data.message))
		} else if (response.data.code == 60000002) {
			Message({
				message: response.data.msg || response.data.message,
				type: 'error',
			});
			return Promise.reject(new Error(response.data.msg || response.data.message))
		} else if (response.data.data != null && response.data.data.code == 500) {
			Message({
				message: response.data.data.msg || response.data.data.message,
				type: 'error',
			});
			return Promise.reject(new Error(response.data.msg || response.data.message))
		} else if (response.data.code == 200 || response.data.data.code == 200) {
			return response;
		} 
	},
	error => {
		/***** 处理结束 *****/
		console.log('err' + error)
		Message({
			message: error || 'Error',
			type: 'error',
			duration: 5 * 1000
		})
		return Promise.resolve(error);
	}
)

export function request(config) {
	return service(config);
}