<template>
	<div class="assembly">
		<span>共 {{ total }} 条</span>
		<span v-if="type == 1">{{ Math.ceil(total / go ) }} 页</span>
		<span @click="head"  v-if="type == 1"
			:style="{'color':this.currentPage == 1 ? '#000':'#003399' ,'cursor':this.currentPage == 1 ? 'not-allowed':'pointer'}">首页</span>
		<div v-if="type != 1">
			<el-pagination :page-size="pageSize" :pager-count="5" :current-page="currentPage" 
				layout="prev, pager, next" :total="total" next-text="下一页" prev-text="上一页"
				@current-change="currentchange">
			</el-pagination>
		</div>
		<div v-else>
			<el-pagination :page-size="pageSize" :pager-count="11" :current-page="currentPage" 
				layout="prev, pager, next,jumper" :total="total" next-text="下一页" prev-text="上一页"
				@current-change="currentchange">
			</el-pagination>
		</div>
		<span @click="tail"  v-if="type == 1"
			:style="{'color':this.currentPage == Number(Math.ceil(this.total / go )) ? '#000':'#003399' , 'cursor': this.currentPage == Number(Math.ceil(this.total / go )) ? 'not-allowed' : 'pointer'}">末页</span>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		props: {
			total: {
				type: Number,
				default: 0
			},
			pageSize: {
				type: Number,
				default: 10
			},
			go:{
				type: Number,
				default: 10
			},
			type:{
				type: Number,
				default: 1
			},
			refreshNum:{
				type: [Number,String],
				default: 1
			},
			pageNo:{
				type: Number,
				default: 1
			}
		},
		watch:{
		  refreshNum:{
		    handler(val){
				this.currentPage = 1
			},
			deep: true,
			immediate: true
		  },
		  pageNo:{
			handler(val){
				if(val == 1){
					this.currentPage = 1
				}
			},
			deep: true,
			immediate: true
		  }
		},
		data() {
			return {
				currentPage: 1,
				page:''
			}
		},
		created() {},
		methods: {
			currentchange(e) {
				this.currentPage = e
				this.$emit('currentchange', e)
				window.scrollTo(0, 0)
			},
			head() {
				if (this.currentPage == 1) return
				this.currentPage = 1
				this.$emit('currentchange', 1)
				window.scrollTo(0, 0)
			},
			tail() {
				if (this.currentPage == Number(Math.ceil(this.total / this.go))) return
				this.currentPage = Number(Math.ceil(this.total / this.go))
				if(Number(Math.ceil(this.total / this.go)) == 0 ){
					this.$emit('currentchange', 1)
				}else{
					this.$emit('currentchange', Number(Math.ceil(this.total / this.go)))
				}
				window.scrollTo(0, 0)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.assembly {
		margin-top: 27PX;
		display: flex;
		align-items: center;
		font-size: 14PX;
		font-family: Source Han Sans CN;
		padding-bottom: 10PX;
	}

	.assembly span {
		margin-right: 5PX;
		cursor: pointer;
	}

	.assembly ::v-deep .el-pager .number {
		font-size: 14PX;
		font-weight: 400;
		color: #003399;
		font-family: Source Han Sans CN;
	}

	.assembly ::v-deep .el-pager li.active {
		color: #000;
		cursor: default;
		background: none;
	}

	.assembly ::v-deep .el-pager li {
		min-width: 0 !important;
	}

	.assembly ::v-deep .btn-prev {
		color: #003399;
		padding-right: 5PX;
	}

	.assembly ::v-deep .btn-next {
		color: #003399;
		padding-left: 5PX;
	}

	.assembly ::v-deep .el-pagination button:disabled {
		color: #000 !important;
		background: none;
		cursor: not-allowed;
	}

	.assembly ::v-deep .el-pagination span {
		font-size: 14PX;
		font-family: Source Han Sans CN;
	}

	.assembly ::v-deep .el-pagination {
		padding: 0;
	}
	.assembly ::v-deep .el-pagination .el-pagination__jump{
		margin-left: 0 !important;
		margin-right: 10PX;
	}
	.assembly ::v-deep .el-pagination .btn-next{
		background: none;
	}
	.assembly ::v-deep .el-pager li{
		background: none;
	}
</style>