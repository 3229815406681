<template>
	<div class="box">
		<div class="inner-box">
			<img class="footer-box" :src="data.file1" alt="" @click="getfooter">
			<div class="information">
				<div>地址：{{data.address}} 邮编：{{data.postcode}}</div>
				<div>联系电话：{{data.telephone}} 办公邮箱：{{data.email}}</div>
				<div style="display: flex;">
					<div>版权所有：</div>
					<div class="digest" v-html="data.digest"></div>
				</div>
			</div>
			<div class="qr">
				<img class="wb-wx1" :src="data.file2" alt="">
				<div class="wbtitle">官方微信服务号</div>
			</div>
			<div class="qrwx">
				<img class="wb-wx1" :src="data.file3" alt="">
				<div class="wbtitle">官方微信订阅号</div>
			</div>
			<div class="qrwb">
				<img class="wb-wx1" :src="data.file4" alt="">
				<div class="wbtitle">官方微博</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				data:{}
			}
		},
		created() {
			this.findFooter()
		},
		methods: {
			findFooter() {
				this.$api.findFooter().then(res => {
					this.data = res.data.data[0]
					let img = this.data.file2
					this.$store.commit("SET_IMG",img)
				})
			},
			getfooter(){
				let url = 'https://bszs.conac.cn/sitename?method=show&id=DD9E323BD59F275FE05310291AACFF79'
				window.open(url, '_blank');
			}
		}
	}
</script>
<style  lang="scss" scoped>
	.digest ::v-deep a{
		color: #fff;
	}
	.box {
		height: 145PX;
		background: #404040;
		display: flex;
		justify-content: center;
	}

	.inner-box {
		margin-top: 12PX;
		width: 1200PX;
		height: 119PX;
		display: flex;
	}

	.footer-box {
		width: 66PX;
		height: 82PX;
		margin-top: 16PX;
		cursor: pointer;
	}

	.information {
		width: 657PX;
		height: 77PX;
		font-size: 12PX;
		font-family: MicrosoftYaHei;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 27PX;
		margin-top: 16PX;
		margin-left: 19PX;
	}

	.hui {
		width: 24PX;
		height: 44PX;
		margin-left: 6PX;
		margin-top: -7PX;
	}

	.qr {
		margin: 6PX 0 0 158PX;
		display: flex;
		flex-flow: column;
	}

	.wb-wx1 {
		width: 88PX;
		height: 82PX;

	}

	.wbtitle {
		color: #FFFFFF;
		font-size: 12PX;
		text-align: center;
		margin-top: 10PX;
	}

	.qrwx {
		margin: 6PX 0 0 20PX;
		display: flex;
		flex-flow: column;
	}

	.qrwb {
		margin: 6PX 0 0 20PX;
		display: flex;
		flex-flow: column;
	}
</style>